import React from 'react';
import {styled} from 'styled-components';
import Navbar from './Navbar';

const Container = styled.div`
  width: 100%;
  height: 14vh;
  /* border: 2px solid black; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: #FFFFFF;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px 4px rgba(0,0,0,0.3);
`;

const Header = () => {
  return (
    <Container>
      <Navbar/>
    </Container>
  )
}

export default Header