import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Card = styled.div`
    width: 33vw;
    height: auto;
    padding: 4vh 1vw;
    min-width: fit-content;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2vh;
    align-items: center;
    background-color: #FFC734;
    overflow-y: visible;
    cursor: pointer;

    &:hover img{
        transform: translateY(-43px) translateZ(0) rotate(-8deg);
    }

    &:hover{
        box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.3);
    }

    @media (max-width: 1200px){
        width: 40vw;
        padding: 2vh 1vw;
    }

    @media ( max-width:900px){
        width: 44vw;
    }
    
    @media (max-width:680px){
        width: 60vw;
    } 

    @media (max-width:568px){
        width: 80vw;
        height: 34vh;
    }
`;

const TopDiv = styled.div`
    width: 90%;
    height: 18vh;
    color: #ffffff;
    background-color: #2C2E35;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 1vh 0;
    position: relative;

    @media (max-width:1200px){
        height: 16vh;
    }
    @media (max-width:1050px){
        height: 12vh;
    }
    @media (max-width:576px){
        height: 14vh;
    }

    clip-path: inset(-400% 0% 2% 0%);

`;

const LongBtn = styled.button`
    width: 90%;
    height: 10vh;
    background-color: #ffffff;
    color: #2C2E35;
    border-radius: 15px;
    border: none;
    font-size:1.8em;
    font-weight: 500;
    
    @media (max-width:1200px){
        height: 8vh;
        font-size: 25px;
    }

    @media (max-width:768px){
        font-size: 22px;
    }
    @media (max-width:576px){
        font-size: 18px;
    }
   
`;

const NameDiv = styled.div`
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left:2vw;
    overflow: hidden;
    position: relative;

    @media (max-width:680px){
        padding-left: 4vw;
    } 

    @media (max-width:568px){
        padding-left: 6vw;
    }
`;

const Img = styled.img`
    width: 8vw;
    height: 60vh;
    object-fit: contain;
    /* border: 2px solid red; */
    transition: transform 0.2s ease-in-out;
    z-index: 18;

    @media (max-width:1200px){
        height: auto;
        width: 12vw;
    }

    @media (max-width: 900px){
        width: 12vw;
    }

    @media (max-width: 680px){
        width: 16vw;
    }

    @media (max-width: 576px){
        width: 26vw;
    }

    top: -20vh;
    right:0;

    @media (max-width:1050px){
        top: -16vh;
    }
    @media (max-width:576px){
        top: -18vh;
    }


`;

const CompanyName = styled.h2`
    font-weight: 700;

    @media (max-width:1200px){
        font-size: 25px;
    }
    @media (max-width:768px){
        font-size: 22px;
    }
    @media (max-width:576px){
        font-size: 18px;
    }

`;

const ModelName = styled.h2`
    white-space: nowrap;
    font-weight: 700;
    @media (max-width:1200px){
        font-size: 25px;
    }
    @media (max-width:768px){
        font-size: 22px;
    }
    @media (max-width:576px){
        font-size: 18px;
    }
    
`;


const CardProduct = ({company,name,image}) => {
    const navigate = useNavigate();
    const modelName = company === "IMPACT" ? "smart" : "itl"
  return (
        
        <Card>
            <TopDiv>
                <NameDiv>
                    <CompanyName>{company}</CompanyName>
                    <ModelName>{name}</ModelName>
                </NameDiv>

                <Img src={image} />
                
            </TopDiv>
            <LongBtn onClick={()=>navigate(`/product?type=${modelName}`)}>More Info</LongBtn>
        </Card>
  )
}

export default CardProduct