import React from 'react';
import {styled} from 'styled-components'
import CardProduct from './cards/CardProduct';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10vh 0 20vh 5vw;
  background-color: #EFEFEF;

  @media (max-width:900px){
    padding-left: 2vw;
  }
`;

const Title = styled.h1`
  font-size: 3.4em;
  color: var(--secondary);
  font-weight:500;

  @media (max-width:900px){
    padding-left: 5vw;
  }

  @media(max-width:500px){
    font-size: 2.5em;
  }
`;

const Subtitle = styled.h4`
  color: #2C2E35;
  font-weight:300;
  @media (max-width:900px){
    padding-left: 5vw;
  }

  @media (max-width:576px){
    text-align: left;
  }
`;

const CardsDiv = styled.div`
  width: 75vw;
  height: auto;
  /* min-height: 62vh; */
  /* border: 2px solid black; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 3vw;
  overflow: visible;
  padding: 20vh 0 5vh 0;
  /* padding: 15vh 0 5vh 0; */

  @media (max-width:1200px){
    width: 95vw;
  padding: 10vh 0 5vh 0;

    justify-content: space-around;
  }

  @media(max-width:680px){
    padding-top: 19vh;
    flex-direction: column;
    align-items: center;
    gap: 24vh;
    /* min-height: 130vh; */
  }

`;

const OurProducts = () => {
  const CardDetails = [{company:"IMPACT",name:"ROCK BREAKER", image:"/images/smart.png"},
               {company:"ITL",name:"ROCK BREAKER", image:"/images/itl.png"}]
  return (
    <Wrapper id='ourproducts'>
      <Title>Our Products</Title>
      <Subtitle>Building trust with our efforts to continually grow our technology!</Subtitle>
      <CardsDiv>
        {
          CardDetails.map((cd,i)=>(
            <CardProduct key={i} company={cd.company} name={cd.name} image={cd.image}/>
          ))
        }
      </CardsDiv>
    </Wrapper>
  )
}

export default OurProducts