import React, { useState } from 'react';
import {keyframes, styled} from 'styled-components';
import { FaAngleDown } from "react-icons/fa6";


const fadeAnime = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

const Wrapper = styled.div`
    width: 100%;
    height: 68vh;
    display: flex;
    flex-direction: column;
`;

const BannerDiv = styled.div`
  width: 100%;
  height: 85%;
  background-color: #2C2E35;
  position: relative;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

`;

const Title = styled.h1`
  position: absolute;
  top: 35%;
  left: 5%;
  color: #FFFFFF;
  font-size:5.6em;
  max-width: 100%;
  font-weight:800;

  @media (max-width:1000px){
    font-size:4em;
  }

  @media (max-width:576px){
    font-size:2.1em;
  }
`;

const BottomDiv = styled.div`
width: 100%;
height: 15%;
background-color: #2C2E35;
`;

const SelectDiv = styled.div`
  width: 18vw;
  min-width: fit-content;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15vw;
  padding-left: 1vw;
  position: relative;
  /* border: 2px solid blue; */
  cursor: pointer;

  @media (max-width:576px){
    margin-left: 1vw;
  }
`;

const ActiveModel = styled.h3`
  color: #ffffff;
`;

const OptionBox = styled.div`
  width: 100%;
  min-width: fit-content;
  height: auto;
  border-radius: 0 0 10px 10px;
  color: #ffc734;
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1vh;
  position: absolute;
  top: 11vh;
  left: 0;
  padding: 1vh 0;
  cursor: pointer;
  z-index: 10;

  animation: ${fadeAnime} 0.3s ease-in-out;

`;

const Options = styled.h3`
  font-size: 16px;
`;

const Hr = styled.hr`
  margin: 0;
`;

const Banner = ({model,setModel}) => {
  const [isOpen,setIsOpen] = useState(false);

  const selectClick = ()=>{
    setIsOpen(!isOpen)
  }

  return (
    <Wrapper>
     <BannerDiv >
        <Title>
          {model === "smart" && "IMPACT ROCK BREAKER"}
          {model === "itl" && "ITL ROCK BREAKER"}
        </Title>
        {model === "smart" && <Img src={'/images/breaker.png'} alt='breaker'/>}
        {model === "itl" && <Img src={'/images/jcb.jpg'} alt='jcb'/>}
     </BannerDiv>
     <BottomDiv>
        <SelectDiv onClick={selectClick}>
          <ActiveModel>
            {model === "smart" && "IMPACT ROCK BREAKER"}
            {model === "itl" && "ITL ROCK BREAKER"}
          </ActiveModel>

          <FaAngleDown
          className={`${isOpen ? 'rotate-clock' : ''}`}
          style={{color:"#FFC734",fontSize:"48px"}}/>
          

          {
            isOpen &&
            <OptionBox>
              <Options onClick={()=>setModel("itl")}>ITL ROCK BREAKER</Options>
              <Hr/>
              <Options onClick={()=>setModel("smart")}>IMPACT ROCK BREAKER</Options>
            </OptionBox>

          }
        </SelectDiv>

     </BottomDiv>

    </Wrapper>
  )
}

export default Banner