import React from 'react';
// import { Button } from 'react-bootstrap';
import { FaAngleDown } from "react-icons/fa6";
import styled,{keyframes} from 'styled-components';

const fadeInAnimation = keyframes`
  from{
    opacity: 0;
    transform: translateY(10px);
  }

  to{
    opacity: 1;
    transform: translateY(0px);
  }
`;

const resizeCircle = keyframes`
  from{
    width:100px;
    height: 100px;
    /* transform: translateZ(0); */
  }

  to{
    width: 400px;
    height: 400px;
    /* transform: translateZ(0); */

  }
`;

const resizeCircleSm = keyframes`
  from{
    width:100px;
    height: 100px;
    /* transform: translateZ(0); */
  }

  to{
    width: 330px;
    height: 330px;
    /* transform: translateZ(0); */

  }
`;

const resizeImg = keyframes`
  from{
    width: 80px;
    height: 200px;
    transform:translateX(0px) translateZ(0) rotate(0deg);
  }

  to{
    width: 250px;
    height: 550px;
    transform:translateX(60px) translateZ(0) rotate(15deg);
  }
`;

const resizeImgSm = keyframes`
  from{
    width: 80px;
    height: 200px;
    transform:translateX(0) translateZ(0) rotate(0deg);
  }

  to{
    width: 170px;
    height: 450px;
    transform:translateX(60px) translateZ(0) rotate(15deg);
  }
`;

const bounceArrow = keyframes`
  0%,100%{
    transform: translateY(0);
  }

  40%{
    transform: translateY(5px);
  }

  80%{
    transform: translateY(10px);
  }
`;

const Wrapper = styled.div`
    height: auto;
    /* padding: 30vh 4vw 0 4vw; */
    margin: 0;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* position: relative; */

    
`;

const BannerDiv = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  height: auto;
  background-image:url("/images/home-wall.jpg");
  /* background-image:url("/images/main-banner2.jpg"); */
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
    border: 0;
    padding: 20vh 4vw 20vh 4vw;
  display: flex;
  align-items: center;
    justify-content: center;
    gap: 20vw;

    @media (max-width:1200px){
      /* min-height: 100vh; */
      gap: 5vw;
    }

    @media (max-width:800px){
      flex-direction: column;
      align-items: center;
      justify-content: center;
    padding: 10vh 2vw 10vh 2vw;
     
      gap: 5vh;
    }
`;

const LeftDiv = styled.div`
  width: fit-content;
  height: fit-content;
  /* border:2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  /* gap: 1vh; */

  /* @media(max-width:900px){
    padding: 0 2vw;
  } */
`;

const RightDiv = styled.div`
  width: 400px;
  height: 400px;
  /* border:2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: #ffffff;
  font-weight:900;
  font-size: 4.5em;

  animation: ${fadeInAnimation} 1.5s ease-in-out;

  @media (max-width:1200px){
    font-size: 3em;
  }
  @media (max-width:900px){
    font-size: 2.6em;
  }
  @media (max-width:576px){
    font-size: 2.4em;
  }
`;

const Subtitle = styled.h2`
  color: #ffffff;
  font-weight:300;
  font-size: 1.4em;

  animation: ${fadeInAnimation} 1.5s ease-in-out;

  @media (max-width:1200px){
    font-size: 1.6em;
  }

  @media (max-width:900px){
    font-size: 1.4em;
  }

  @media (max-width:426px){
    font-size: 1em;
  }
`;

const Btn = styled.button`
   background-color : #2C2E35;
  color:#FFC734;
  border:none;
  border-radius: 5px;
  width:12vw;
  height:auto;
  /* font-size:1.6em; */
  font-size:16px;
  padding: 2vh 1.5vw;
  min-width:fit-content;
  min-height:fit-content;
  font-weight:500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vh;

  animation: ${fadeInAnimation} 1.5s ease-in-out;

  @media (max-width:1200px){
    padding: 1vh 1.5vw;
  }

  @media (max-width:800px){
    width:10vw;
    /* height:5vh; */
    font-size:1em;
    padding: 2vh 2vw;
  }

  @media (max-width:576px){
    width:10vw;
    /* height:5vh; */
    font-size:12px;
    padding: 1.5vh 7vw;
    margin-top: 3vh;
  }
`;


const Circle = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #FFC734;
  filter:drop-shadow(5px 8px 10px #010101);
  position: relative;

  animation: ${resizeCircle} 1.5s ease-in;
  
  @media (max-width:800px){
    animation: ${resizeCircleSm} 1.5s ease-in;
    width: 330px;
    height: 330px;
  }
`;

const Img = styled.img`
  width: 250px;
  height: 550px;
  object-fit: contain;
  position: absolute;
  top: -60px;
  /* left: 40px; */
  left: 0;
  transform:translateX(60px) rotate(15deg);
  filter:drop-shadow(7px 7px 2px #2c2c2c);

  animation: ${resizeImg} 1.5s ease-in;

  @media (max-width:800px){
    animation: ${resizeImgSm} 1.5s ease-in;
  
    width: 170px;
    height: 450px;
    top:-40px;
    /* left: 0; */
  }
`;

const BottomDiv  = styled.div`
  width: 100%;
  height: 13vh;
  background-color: #2C2E35;
  /* position: absolute;
  bottom: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ArrowDiv = styled.div`
  width: fit-content;
  height: fit-content;
  animation:${bounceArrow} 1.5s ease-in infinite;
`;

const MainBanner = ({ScrollToProducts}) => {
  return ( 
    <Wrapper id='mainbanner'>

      <BannerDiv>
      <LeftDiv>
        <Title><span style={{color:"#FFC734"}}>Impact</span> Equipments</Title>
        <Subtitle>A hydraulic breaker manufacturer</Subtitle>
        <Btn onClick={ScrollToProducts}>Learn More</Btn>
        {/* <Button style={ButtonStyle}>Learn More</Button> */}
      </LeftDiv>

      <RightDiv>
        <Circle>
        <Img src='/images/smart.png' alt='breaker'/>
        </Circle>
      </RightDiv>

      </BannerDiv>

      <BottomDiv>
        <ArrowDiv onClick={ScrollToProducts}>
          {/* <ExpandMoreRoundedIcon style={{color:"#FFC734",fontSize:"72px"}}/> */}
          <FaAngleDown style={{color:"#FFC734",fontSize:"72px"}}/>
        </ArrowDiv>
      </BottomDiv>
    </Wrapper>
  )
}

export default MainBanner