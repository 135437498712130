import React from "react";
import { styled } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  min-height: 40vh;
  color: var(--secondary);
  background-color: var(--primary);
  display: flex;
  padding: 5vh 5vw 10vh 5vw;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LeftDiv = styled.div`
  width: 50%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 3vh;
  }
`;
const Logo = styled.img`
  width: 9vw;
  height: auto;

  @media (max-width: 576px) {
    width: 30vw;
  }
`;

const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RightDiv = styled.div`
  width: 50%;
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 5vh;

  @media (max-width: 1000px) {
    width: 100%;
    /* padding-left: 5vw; */
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /* gap: 10vw; */
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 5vh;
  }
`;

const ContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* border: 2px solid green; */
`;

const Qmark = styled.div`
  & span > a {
    text-decoration: none;
    color: inherit;
  }
`;

const Footer = () => {
  return (
    <Wrapper id="footer">
      <LeftDiv>
        <Logo src="redLogo.png" alt="logo" />
        <AddressDiv>
          <h5 style={{ fontWeight: "700" }}>Impact Equipments</h5>
          <span style={{ fontSize: "16px", fontWeight: "500" }}>
            Keralatech
          </span>
          <span style={{ fontSize: "16px", fontWeight: "300" }}>
            #MMCXlX/185K-1, 1st Floor, Chalil Building
          </span>
          <span style={{ fontSize: "16px", fontWeight: "300" }}>
            Chalikadavu Bridge Road, Muvattupuzha
          </span>
          <span style={{ fontSize: "16px", fontWeight: "300" }}>
            Ernakulam District, Kerala - 686661
          </span>
        </AddressDiv>
      </LeftDiv>

      <RightDiv>
        <ContactDiv>
          <span style={{ fontSize: "16px", fontWeight: "400" }}>
            <strong>Office:</strong> 99 61 44 23 44, 95 44 44 06 44
          </span>
          <span style={{ fontSize: "16px", fontWeight: "400" }}>
            <strong>E-mail:</strong> impactequipmentsllc@gmail.com
          </span>
        </ContactDiv>

        <Qmark>
          <span style={{ fontSize: "16px", fontWeight: "400" }}>
            <a href="https://qmarktechnolabs.com/">
              Developed by Qmark Technolabs
            </a>
          </span>
        </Qmark>
      </RightDiv>
    </Wrapper>
  );
};

export default Footer;
