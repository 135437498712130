import React, { useEffect, useState } from 'react'
import Banner from '../components/banners/Banner'
import SmartSpecs from '../components/SmartSpecs';
import SmartTech from '../components/SmartTech';
import ItlSpecs from '../components/ItlSpecs';
import ItlTech from '../components/ItlTech';
import Footer from '../components/Footer';
import { useLocation} from 'react-router-dom';

const Product = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const type = searchParams.get('type');

  const modelName = type ? type : "smart";

  const [model,setModel] = useState(modelName)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[model])

  return (
    <div>
      <Banner model={model} setModel={setModel}/>

      {model==="smart" && <SmartSpecs/>}
      {model==="smart" && <SmartTech/>}

      {model ==="itl" && <ItlSpecs/>}
      {model ==="itl" && <ItlTech/>}

      <Footer/>
    </div>
  )
}

export default Product