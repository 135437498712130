import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding:2vh 2vw 4vh 2vw;

`;

const RowDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
`;

const ColumnDiv = styled.div`
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;

  @media (max-width: 800px) {
    flex: 50%;
    max-width: 50%;
    }

@media (max-width: 600px) {
    flex: 100%;
    max-width: 100%;
    }
`;

const Img = styled.img`
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  
`;

const Gallery = () => {
    const c1Array = ["c1n1.jpg", "c1n2.jpg", "c1n3.jpg", "c1n4.jpg", "c1n5.jpg", "c1n6.jpg"]
    const c2Array = ["c2n1.jpg", "c2n2.jpg", "c2n3.jpg", "c2n4.jpg", "c2n5.jpg"]
    const c3Array = ["c3n1.jpg", "c3n2.jpg", "c3n3.jpg", "c3n4.jpg", "c3n5.jpg", "c3n6.jpg", "c3n7.jpg"]
    const c4Array = ["c4n1.jpg", "c4n2.jpg", "c4n3.jpg", "c4n4.jpg", "c4n5.jpg", "c4n6.jpg"]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Wrapper>
            <RowDiv className='row-div'>

                <ColumnDiv>
                    {
                        c1Array.map((picName, i) => (
                            <Img src={`/images/gallery/${picName}`} alt="Breaker" key={i} />
                        ))
                    }
                </ColumnDiv>
                <ColumnDiv>
                    {
                        c2Array.map((picName, i) => (
                            <Img src={`/images/gallery/${picName}`} alt="Breaker" key={i} />
                        ))
                    }
                </ColumnDiv>
                <ColumnDiv>
                    {
                        c3Array.map((picName, i) => (
                            <Img src={`/images/gallery/${picName}`} alt="Breaker" key={i} />
                        ))
                    }
                </ColumnDiv>
                <ColumnDiv>
                    {
                        c4Array.map((picName, i) => (
                            <Img src={`/images/gallery/${picName}`} alt="Breaker" key={i} />
                        ))
                    }
                </ColumnDiv>


            </RowDiv>
        </Wrapper>
    )
}

export default Gallery