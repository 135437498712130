import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './pages/Home';
import Notfound from './pages/Notfound';
import Layout from './components/Layout';
import Product from './pages/Product';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import MainGallery from './pages/MainGallery';
import Videos from './pages/Videos';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/product' element={<Product/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/gallery' element={<MainGallery/>} />
            <Route path='/images' element={<Gallery/>} />
            <Route path='/videos' element={<Videos/>} />
            <Route path='*' element={<Notfound/>} />
          </Routes>

        </Layout>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
