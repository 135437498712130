import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import {ClipLoader} from 'react-spinners';

const StyledForm = styled(Form)`
  max-width: 70vw;
  min-width: 35vw;
  height: auto;
  padding: 5vh 5vw;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5vh;
`;

const Btn = styled(Button)`
  margin-top: 10px;
  background-color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  border: none;
  
`;

const ContactForm = () => {

  const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID ;
  const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID ;
  const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY ;

  useEffect(()=>{
      emailjs.init(PublicKey)
  },[])

  const [formData, setFormData] = useState({
    name:'',
    email: '',
    phone:'',
    subject:'',
    message:''
  });

  const [loading, setLoading] = useState(false)

  const [successMsg, setSuccessMsg] = useState(null)

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Please enter valid email';
    }

    if (!formData.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    if(!validateForm()) return;

    setLoading(true);

    emailjs.sendForm(
      ServiceId,
      TemplateId,
      e.target,
      PublicKey
    )
      .then((result) => {
        console.log("Email sent successfully!");

        setSuccessMsg('Message sent successfully');

        setTimeout(()=>{
            setSuccessMsg(null)
        },[4000])

        setFormData({
          name: '',
          email: '',
          phone:'',
          subject: '',
          message: '',
        });

        setLoading(false);

        console.log('Form submitted:', result);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setErrors((prev)=>({...prev, server:"Something went wrong"}));
        setLoading(false);

      });

  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Form.Group controlId="name">
        <Form.Control
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />

        {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Control
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />

        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
      </Form.Group>

      <Form.Group controlId="phone">
        <Form.Control
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
        />

      </Form.Group>

      <Form.Group controlId="subject">
        <Form.Control
          type="text"
          name="subject"
          placeholder="Subject"
          value={formData.subject}
          onChange={handleChange}
        />

        {errors.subject && <span style={{ color: 'red' }}>{errors.subject}</span>}
      </Form.Group>

      <Form.Group controlId="message">
        <Form.Control
          as="textarea"
          rows={4}
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          style={{minHeight:"100px",maxHeight:"100px"}}
        />
        {errors.message && <span style={{ color: 'red' }}>{errors.message}</span>}
      </Form.Group>
      
      <Btn type="submit" disabled={loading} style={{backgroundColor:"#ffc734"}}>
        {loading ? <ClipLoader size={16} color='#ffffff'/> :"Send Message"}
      </Btn>

      {errors.server && <span style={{ color: 'red' }}>{errors.server}</span>}
      {successMsg && <span style={{ color: 'green' }}>{successMsg}</span>}

    </StyledForm>
  );
};

export default ContactForm;