import React, { useEffect } from 'react';
import OurProducts from '../components/OurProducts';
import AboutUs from '../components/AboutUs';
import Footer from '../components/Footer';
import MainBanner from '../components/banners/MainBanner';
import styled from 'styled-components';

const Wrapper = styled.div` 
  /* width: 100%; */
  padding:0;
  margin: 0;
  overflow:hidden
`;

const Home = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const ScrollToProducts = ()=>{
    const target = document.getElementById("ourproducts");
    window.scrollTo({
      top:target?.offsetTop,
      behavior:'smooth'
    })
  }

  return (
    <Wrapper>
      <MainBanner ScrollToProducts={ScrollToProducts}/>
      <AboutUs/>
      <OurProducts/>
      <Footer/>
    </Wrapper>
  )
}

export default Home