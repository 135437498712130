import React, { useEffect } from 'react';
import styled from 'styled-components';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BannerDiv = styled.div`
  width: 100%;
  height: 45vh;
  background-color: #2C2E35;
  position: relative;
`;

const Banner = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const MainDiv = styled.div`
    width: 80vw;
    height:auto;
    /* border: 2px solid grey; */
    padding: 5vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled.h1`
  position: absolute;
  top: 35%;
  left: 5%;
  color: var(--primary);
  font-size:4.4em;
  max-width: 100%;
  font-weight:800;

  @media (max-width:1000px){
    font-size:4em;
    padding-left: 5vw;

  }

  @media (max-width:576px){
    font-size:3em;
  }

`;


const Contact = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
      },[])

  return (
    <Wrapper>
        <BannerDiv>
            <Banner src='/images/mailbox.jpg' alt='contact-banner'/>
            <Title>Contact Us</Title>
        </BannerDiv>

        <MainDiv>
            <ContactForm/>
        </MainDiv>

        <Footer/>
    </Wrapper>
  )
}

export default Contact