import React from "react";
import styled, { keyframes } from "styled-components";

const growAnime = keyframes`
  0%{
    transform: scale(0.8);
  }
  100%{
    transform: scale(1);
  }
`;

const VidContainer = styled.div`
  width: 43vw;
  height: 70vh;
  border: 2vh solid #ffc734;
  border-radius: 15px;
  background-color: #ffc734;
  box-shadow: -5px 5px 5px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 1200px) {
    // top: 34vh;
    right: 5vw;
    height: 45vh;
  }

  @media (max-width: 1000px) {
    position: static;
    width: 60%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 94%;
  }
`;

const VideoElem = styled.video`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  animation: ${growAnime} 1s ease-in-out;
  object-fit: fill;
`;
const VideoBox = ({ vidSrc }) => {
  return (
    <VidContainer>
      <VideoElem controls muted>
        <source src={vidSrc} type="video/mp4" />
      </VideoElem>
    </VidContainer>
  );
};

export default VideoBox;
