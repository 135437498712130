import React, { useEffect} from 'react'
import styled from 'styled-components'


const LabelDiv = styled.div`
    width: 100%;
    height: auto;
    min-height: fit-content;
    /* border: 2px solid blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 3vw 10vw 3vw;

    @media(max-width:1050px){
      justify-content: flex-start;
      padding: 8vh 6vw 10vh 18vw;
    }

    @media(max-width:576px){
      padding: 8vh 10vw 10vh 10vw;
    }
    
`;

const ImgDiv = styled.div`
  min-width: fit-content ;
  min-height: fit-content;
  max-height: 80vh;
  /* border: 2px solid red; */
  position: relative;

`;

const Img = styled.img`
  width: 8vw;
  height: 60vh;
  transform: rotate(15deg);
  z-index: 10;
  object-fit: contain;
  opacity: 0;

  @media(max-width:1050px){
    transform: rotate(0deg);
    /* width: 15vw; */
    width: 100%;
    height: 55vh;
  }

  @media(max-width:576px){
    transform: rotate(0deg);
    width: 100%;
    height: 55vh;
  }
`;


const RectLabelLT = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding: 2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  background-color: var(--secondary);
  position: absolute;
  top: 0;
  opacity: 0;

  @media(min-width:1050px){
    left: -30vw;
  }

  @media(max-width:1050px){
    top: 0;
  }

  @media(max-width:1200px){
    font-size: 16px;
  }

  @media(max-width:1050px){
    right: -40vw;
    
  }

  @media(max-width:576px){
    right: -40vw;
    font-size: 11px;
  }
`;
const RectLabelLM = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding: 2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  background-color: var(--secondary);
  position: absolute;
  top: 25vh;
  opacity: 0;

  @media(min-width:1050px){
    left: -30vw;
  }

  @media(max-width:1050px){
    top: 20vh;
  }

  @media(max-width:1200px){
    font-size: 16px;
  }

  @media(max-width:1050px){
    right: -40vw;
  }

  @media(max-width:576px){
    right: -40vw;
    font-size: 11px;

  }
`;

const RectLabelLB = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding: 2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  background-color: var(--secondary);
  position: absolute;
  top:50vh;
  opacity: 0;


  @media(min-width:1050px){
    left: -30vw;
  }

  @media(max-width:1050px){
    top: 40vh;
  }

  @media(max-width:1200px){
    font-size: 16px;
    right: -40vw;
  }

  @media(max-width:576px){
    right: -40vw;
    font-size: 11px;

  }
`;

const RectLabelRT = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding: 2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  background-color: var(--secondary);
  position: absolute;
  top:10vh;
  right: -25vw;
  opacity: 0;


  @media(max-width:1200px){
    font-size: 16px;
  }

  @media(max-width:1050px){
    top: 10vh;
    right: -40vw;
  }

  @media(max-width:576px){
    right: -40vw;
    font-size: 11px;

  }
`;

const RectLabelRM = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding: 2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  background-color: var(--secondary);
  position: absolute;
  top:30vh;
  right: -30vw;
  opacity: 0;


  @media(max-width:1200px){
    font-size: 16px;
  }

  @media(max-width:1050px){
    top: 30vh;
    right: -40vw;
  }

  @media(max-width:576px){
    right: -40vw;
    font-size: 11px;

  }
`;

const RectLabelRB = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding: 2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  background-color: var(--secondary);
  position: absolute;
  top:50vh;
  right: -20vw;
  opacity: 0;


  @media(max-width:1200px){
    font-size: 16px;
  }

  @media(max-width:1050px){
    top: 50vh;
    right: -40vw;
  }

  @media(max-width:576px){
    right: -40vw;
    font-size: 11px;

  }
`;



const LineLeft = styled.svg`
  width: 13vw;
  height: 26vh;
  position: absolute;
  top: 2vh;
  right: -11.5vw;
  z-index: 2;

  & path{
    stroke: var(--secondary);
  }

  @media (max-width:1050px){
    display: none;
  }
`;
const LineRight = styled.svg`
  width: 13vw;
  height: 26vh;
  position: absolute;
  top: 2vh;
  left: -13vw;
  z-index: 2;

  & path{
    stroke: var(--secondary);
  }

  @media (max-width:1050px){
    display: none;
  }
`;


const LabelSmart = () => {

    const handleScroll = () => {
      let parent = document.getElementById('label-div');
      let smartImg = document.getElementById('smart-img');
      let smartLabels = [];
      smartLabels = [...document.querySelectorAll('.smart-labels')];

      let parentTop = parent.offsetTop;

      if(window.scrollY >= parentTop - 250){

          smartImg?.classList.add('appear');

          smartLabels?.map((sl)=>(
            sl.classList.add('appear-up')
          ))
      }else{
          if(smartImg?.classList.contains('appear')){
              smartImg?.classList.remove('appear')
          }

          if(smartLabels[0]?.classList.contains('appear-up')){
            smartLabels?.map((sl)=>(
              sl.classList.remove('appear-up')
            ))
          }
      }
    };

    useEffect(() => {
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    
  return (
    <LabelDiv id='label-div'>
        <ImgDiv id='smart-label'>

          <Img src='/images/smart.png' alt='Breaker' id='smart-img'/>

          <RectLabelLT className='smart-labels'>
            <span>Superior Power/Efficiency</span>
            <LineLeft>
              <path d="M20 20 L100 20 Q500 500 350 300" strokeWidth="2" fill="none" />

            </LineLeft>
          </RectLabelLT>

          <RectLabelLM className='smart-labels'>
            <span>Extended Durability</span>
            <LineLeft>
              <path d="M20 20 L400 20 " strokeWidth="2" fill="none" />

            </LineLeft>
          </RectLabelLM>

          <RectLabelLB className='smart-labels'>
            <span>Simple/Easy Maintenance</span>
            <LineLeft>
              
              <path d="M20 20 L100 20 Q300 -100 300 -200" strokeWidth="2" fill="none" />

            </LineLeft>
          </RectLabelLB>

          <RectLabelRT className='smart-labels'>
            <span>Reliable Availability</span>
            <LineRight>
              <path d="M200 20 L120 20 Q40 60 40 60" strokeWidth="2" fill="none" />

            </LineRight>
          </RectLabelRT>

          <RectLabelRM className='smart-labels'>
            <span>Quick Customer Service</span>
            <LineRight>
              <path d="M20 20 L200 20" strokeWidth="2" fill="none" />

            </LineRight>
          </RectLabelRM>

          <RectLabelRB className='smart-labels'>
            <span>High Productivity</span>
            <LineRight >
              <path d="M200 20 L120 20 Q-500 -500 350 -300" strokeWidth="2" fill="none" />


            </LineRight>
          </RectLabelRB>

        </ImgDiv>
      </LabelDiv>
  )
}

export default LabelSmart