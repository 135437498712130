import React, { useEffect } from "react";
import styled from "styled-components";
import itlData from "../datas/itlData.json";

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  /* border: 2px solid red; */
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2vh solid #ffffff;
  gap: 50vh;

  @media (max-width: 1050px) {
    gap: 30vh;
  }
  @media (max-width: 576px) {
    gap: 15vh;
  }
`;

const Title = styled.h1`
  font-size: 3.4em;
  color: var(--secondary);
  font-weight: 500;

  @media (max-width: 900px) {
    padding-left: 5vw;
  }

  @media (max-width: 500px) {
    font-size: 2.5em;
  }
`;

const MainDiv = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
`;

const Dome = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  position: absolute;
  opacity: 0;

  @media (max-width: 1050px) {
    border-radius: 50% 50% 0 0 / 50% 50% 50% 50%;
  }
`;

const Img = styled.img`
  width: 40%;
  height: 56vh;
  position: absolute;
  top: -40vh;
  left: 30vw;
  object-fit: contain;
  opacity: 0;

  @media (max-width: 1050px) {
    top: -30vh;
  }

  @media (max-width: 768px) {
    width: 60%;
    height: 36vh;
    top: -20vh;
    left: 20vw;
  }
`;

const TableResponsive = styled.div`
  overflow: auto;
  font-size: 14px;
  margin-top: 10vh;
  max-width: 76vw;
  max-height: 67vh;
  border-radius: 10px;
  box-shadow: 5px 5px 22px -6px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;

  position: absolute;

  @media (max-width: 768px) {
    font-size: 10px;
  }

  &::-webkit-scrollbar {
    width: 0.01em;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const ItlTech = () => {
  const handleScroll = () => {
    let parent = document.getElementById("itl-tech-wrap");
    let itlImg = document.getElementById("itl-set-img");
    let itlDome = document.getElementById("itl-dome");

    let parentTop = parent.offsetTop;

    if (window.scrollY >= parentTop - 200) {
      console.log("within");

      itlImg?.classList.add("appear");
      itlDome?.classList.add("appear-up-dome");
    } else {
      console.log("without");
      if (itlImg?.classList.contains("appear")) {
        itlImg?.classList.remove("appear");
      }

      if (itlDome?.classList.contains("appear-up-dome")) {
        itlDome?.classList.remove("appear-up-dome");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Wrapper id="itl-tech-wrap">
      <Title>Technical Details</Title>

      <MainDiv id="itl-tech-main">
        <Dome id="itl-dome" />

        <Img src="/images/itlset.png" alt="itl-breakers" id="itl-set-img" />

        <TableResponsive className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Descriptions</th>
                <th>Unit</th>
                <th>ITL HB 530</th>
                <th>ITL HB 750</th>
                <th>ITL HB 1000</th>
                <th>ITL HB 1400</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Operating Weight</td>
                <td>Kg</td>
                {itlData?.opwt.map((owt, i) => (
                  <td key={i}>{owt}</td>
                ))}
              </tr>
              <tr>
                <td>Height</td>
                <td>mm</td>
                {itlData?.ht.map((owt, i) => (
                  <td key={i}>{owt}</td>
                ))}
              </tr>
              <tr>
                <td>Required Oil Flow</td>
                <td>L/min</td>
                {itlData?.flow.map((owt, i) => (
                  <td key={i}>{owt}</td>
                ))}
              </tr>
              <tr>
                <td>Operating Pressure</td>
                <td>Bar</td>
                {itlData?.prs.map((owt, i) => (
                  <td key={i}>{owt}</td>
                ))}
              </tr>
              <tr>
                <td>Impact Rate</td>
                <td>BPM</td>
                {itlData?.rate.map((owt, i) => (
                  <td key={i}>{owt}</td>
                ))}
              </tr>
              <tr>
                <td>Chisel Diameter</td>
                <td>mm</td>
                {itlData?.diam.map((owt, i) => (
                  <td key={i}>{owt}</td>
                ))}
              </tr>
              <tr>
                <td>Carrier Weight</td>
                <td>Ton</td>
                {itlData?.carwt.map((owt, i) => (
                  <td key={i}>{owt}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </TableResponsive>
      </MainDiv>
    </Wrapper>
  );
};

export default ItlTech;
