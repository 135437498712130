import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding-top:10vh;
    border-bottom: 18vh solid var(--primary);
    display: flex;
    align-items:flex-end;
    justify-content: center;
    gap: 10vw;

    @media (max-width:1050px){
        gap: 12vw;
        border-bottom: 8vh solid var(--primary);
    }
    @media (max-width:576px){
        border-bottom: 8vh solid var(--primary);
        gap: 8vw;
    }
`;

const LeftDiv = styled.div`
    /* border: 2px solid red; */
    width: 30vw;
    height: 80vh;
    display: flex;
    align-items: last baseline;
    justify-content: center;
    position: relative;
    /* padding-bottom: 2vh; */

    @media(max-width:1050px){
        height: 50vh;
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media(max-width:1050px){
    width: 24vw;
    height: 56vh;
  }
`;

const Rock = styled.div`
    width: 16vw;
    height: 14vh;
    background-color: var(--primary);
    position: absolute;
    bottom: 0;
    clip-path: polygon(45% 54%, 71% 2%, 78% 67%, 100% 46%, 89% 100%, 23% 100%, 0 9%, 28% 49%, 33% 0);
    opacity: 0;

    @media (max-width:1050px){
        width: 20vw;
    }
    @media (max-width:576px){
        width: 30vw;
        height: 18vh;
    }
`;

const RightDiv = styled.div`
    width: 20vw;
    height: 80vh;
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4vh;
    @media (max-width:1050px){
        height: 50vh;
        width: 30vw;
    }
    @media (max-width:768px){
        width: 35vw;
    }
    @media (max-width:576px){
        width: 40vw;
        height: 50vh;
    }
    @media (max-width:400px){
        width: 46vw;
        height: 50vh;
    }
   
`;


const RectLabelTop = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding:2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 400;
  background-color: var(--secondary);

  opacity: 0;

  @media(max-width:1200px){
    font-size: 16px;
  }

  @media(max-width:576px){
        font-size: 14px;
        padding: 1vh 1vw;
    }

`;

const RectLabelBot = styled.div`
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  padding:2vh 1vw;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 400;
  background-color: var(--secondary);

  opacity: 0;


  @media(max-width:1200px){
    font-size: 16px;
  }

  @media(max-width:576px){
        font-size: 14px;
        padding: 1vh 1vw;
    }

`;

const LabelItl = () => {

    const handleScroll = () => {
        let parent = document.getElementById('itl-label-div');
        let itlImg = document.getElementById('itl-img');
        let theRock = document.getElementById('the-rock');
        let itlLabelsTop = [...document.querySelectorAll('.itl-labels-top')];
        let itlLabelsBottom = [...document.querySelectorAll('.itl-labels-bottom')];
  
        let parentTop = parent.offsetTop;
  
        if(window.scrollY >= parentTop -50){
  
            itlImg?.classList.add('move-down-brkr');
            theRock?.classList.add('move-up-rock')
  
            itlLabelsTop?.map((sl)=>(
              sl.classList.add('move-down-label')
            ));
            
            itlLabelsBottom?.map((sl)=>(
              sl.classList.add('move-up-label')
            ));

        }else{
            if(itlImg?.classList.contains('move-down-brkr')){
                itlImg?.classList.remove('move-down-brkr')
            }

            if(theRock?.classList.contains('move-up-rock')){
                theRock?.classList.remove('move-up-rock')
            }
  
            if(itlLabelsTop[0]?.classList.contains('move-down-label')){
              itlLabelsTop?.map((sl)=>(
                sl.classList.remove('move-down-label')
              ))
            }

            if(itlLabelsBottom[0]?.classList.contains('move-up-label')){
              itlLabelsBottom?.map((sl)=>(
                sl.classList.remove('move-up-label')
              ))
            }
        }
      };
  
      useEffect(() => {
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <Wrapper id='itl-label-div'>
        <LeftDiv>
            <Img src='/images/itl.png' alt='itl-breaker' id="itl-img"/>
            {/* <Rock id='the-rock'/> */}
        </LeftDiv>
        <RightDiv>
            <RectLabelTop className='itl-labels-top'>High Productivity</RectLabelTop>
            <RectLabelTop className='itl-labels-top'>Low Noise</RectLabelTop>
            <RectLabelTop className='itl-labels-top'>Long Durability</RectLabelTop>
            <RectLabelBot className='itl-labels-bottom'>Wide Application Range</RectLabelBot>
            <RectLabelBot className='itl-labels-bottom'>High Efficient Design</RectLabelBot>
        </RightDiv>
    </Wrapper>
  )
}

export default LabelItl