import React from 'react'
import styled from 'styled-components'
import LabelSmart from './LabelSmart';

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: 120vh;
    padding: 10vh 5vw 5vh 5vw;
    margin-top: 5vh;
`;

const HeadDiv = styled.div`
    width: 100%;
    height: 40vh;
    height: auto;
    border-radius: 15px;
    color: #ffffff;
    background-color: var(--secondary);
    position: relative;
    display: flex;
    align-items: center;
    padding: 10vh 4vw 5vh 4vw;
`;

const Details = styled.p`
    font-size: 22px;
    text-align: left;

    @media(max-width:786px){
        font-size: 20px;
    }
    @media(max-width:576px){
        font-size: 16px;
    }
`;

const NameDiv = styled.div`
    min-width: 30%;
    height: auto;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh 1vw;
    position: absolute;
    border-radius: 15px;
    top: -5vh;
    left: 3vw;

`;

const Title = styled.h1`
  font-size: 3.4em;
  color: #2C2E35;
  font-weight:500;

  @media (max-width:1000px){
    font-size: 3em;
  }

  @media(max-width:600px){
    font-size: 2em;
  }

  @media(max-width:350px){
    font-size: 1.4em;
  }
`;


const AdjectiveDiv = styled.div`
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5vh 10vw;
    @media(max-width:1050px){
      padding: 5vh 2vw;
    }
`;

const Adjective = styled.span`
  font-size: 2.2em;
  font-weight: 800;
  opacity: 0.5;
  color: var(--secondary);
  @media(max-width:1050px){
    font-size: 1.6em;
  }
  @media(max-width:768px){
    font-size: 1em;
  }
`;

const SmCircle = styled.div`
  background-color: var(--primary);
  opacity: 0.5;
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  @media(max-width:1050px){
    width: 1.6em;
    height: 1.6em;
  }
  @media(max-width:768px){
    width: 1em;
    height: 1em;
  }
`;



const SmartSpecs = () => {


  return (
    <Wrapper>
      <HeadDiv>
        <NameDiv>
          <Title>Impact Rock Breaker</Title>
        </NameDiv>

        <Details>
          Impact hydraulic breakers have 11 ranges of operating
          weight from 140 kg to 4000 kg suitable for base machines
          up to 60 tons such as skid steer loaders, backhoe loaders
          and excavators. The operating system is combined with
          compressed nitrogen gas to maximize power output impact
          hydraulic breakers specially designed for several
          construction projects for wide range of applications
          such as foundation. mining, quarry and demolitions even
          in very tough conditions.
        </Details>
      </HeadDiv>

      <LabelSmart/>

      <AdjectiveDiv>
          <Adjective>STRONG</Adjective>
          <SmCircle/>
          <Adjective>DURABLE</Adjective>
          <SmCircle/>
          <Adjective>COMPACT</Adjective>
          <SmCircle/>
          <Adjective>UNIQUE</Adjective>
      </AdjectiveDiv>
      
    </Wrapper>
  )
}

export default SmartSpecs