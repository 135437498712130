import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import ImgSlider from "../components/ImgSlider";
import VideoBox from "../components/VideoBox";

const growAnime = keyframes`
  0%{
    transform: scale(0.8);
  }
  100%{
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 0 0 10vh 0;

  @media (max-width: 1000px) {
    align-items: center;
    padding: 2vh 0;
    gap: 4vh;
  }
`;

const LeftTop = styled.div`
  width: 50vw;
  height: 20vh;
  /* border: 2px solid orange; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5vh 0 0 5vw;

  @media (max-width: 1000px) {
    width: 90%;
    height: 12vh;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: 600;
  color: var(--secondary);

  @media (max-width: 1000px) {
    font-size: 3em;
  }

  @media (max-width: 500px) {
    font-size: 2.5em;
  }
`;

const GalleryDiv = styled.div`
  width: 43vw;
  height: 70vh;
  border: 2vh solid #ffc734;
  border-radius: 15px;
  background-color: #ffc734;
  box-shadow: -5px 5px 5px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 1200px) {
    // top: 34vh;
    right: 5vw;
    height: 45vh;
  }

  @media (max-width: 1000px) {
    position: static;
    width: 60%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 94%;
  }
`;

const VideoElem = styled.video`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  animation: ${growAnime} 1s ease-in-out;
  object-fit: fill;
`;

const BotContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5vw;
  padding: 0 3vw;

  @media (max-width: 640px){
    flex-direction: column;
    gap: 4vh;
  }
`;

const MainGallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  return (
    <Wrapper id="main-gallery">
      <LeftTop>
        <Title>Gallery</Title>
      </LeftTop>

      {/* <div className="flex flex-col md:flex-row w-full items-center justify-around gap-4 md:gap-0"> */}
      <BotContainer>
        <GalleryDiv >
          <VideoElem onClick={() => navigate("/videos")}  autoPlay muted >
            <source src="/videos/intro-video.mp4" type="video/mp4" />
          </VideoElem>
        </GalleryDiv>

        <GalleryDiv >
          <ImgSlider />
        </GalleryDiv>

      </BotContainer>
      {/* </div> */}
    </Wrapper>
  );
};

export default MainGallery;
