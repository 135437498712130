import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import smartData from '../datas/smartData.json';
import small from '../datas/small.json';
import medium from '../datas/medium.json';
import heavy from '../datas/heavy.json';

const fadeInAnime = keyframes`
  from{
    opacity:0;
    transform: translateY(25px) translateZ(0);
  }

  to{
    opacity:1;
    transform: translateY(0px) translateZ(0);
  }
`;

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2vh solid #ffffff;
    gap: 30vh;

    @media (max-width:1050px){
        padding-top:5vh;
        gap: 15vh;
    }
  
`;
const Title = styled.h1`
  font-size: 3.4em;
  color: var(--secondary);
  font-weight:500;

  @media (max-width:900px){
    padding-left: 5vw;
  }

  @media(max-width:500px){
    font-size: 2.5em;
  }
`;

const MainDiv = styled.div`
    background-color: var(--primary);
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    gap: 10vh;
    padding: 0 0 10vh 0;
`;

const TopImg = styled.img`
    width: 70vw;
    height: 60vh;
    top: -40vh;
    position: absolute;
    object-fit: contain;
    @media(max-width:1050px){
        top: -30vh;
    }
    @media(max-width:576px){
        width: 95%;
        height: 50vh;
        top: -28vh;
    }
`;

const Img = styled.img`
    width: 60vw;
    height: 50vh;
    object-fit: contain;

    @media(max-width:1050px){
        top: -20vh;
    }
    @media(max-width:576px){
        width: 95%;
        height: 50vh;
        top: -18vh;
    }
`;

const TableResponsive = styled.div`
  overflow: auto;
//   margin-top: 2vh;
//   margin-bottom: 3vh;
  max-width: 90vw;
  max-height: 50vh;
  border-radius: 10px;
  box-shadow: 5px 5px 22px -6px rgba(0,0,0,0.5);
  background-color: #ffffff;
  font-size: 14px;

  animation: ${fadeInAnime} 3s ease-in-out; 

  @media (max-width:768px){
    font-size: 10px;
  }

  &::-webkit-scrollbar {
    width: 0.01em;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;


const SmartTech = () => {
    const handleScroll = ()=>{
        let parent = document.getElementById('smart-tech-wrap');
        let child = document.getElementById('smart-tech-main')
        let parentTop = parent.offsetTop;

        if(window.scrollY >= parentTop -200){
            child.classList.add('appear-up')
        }else{
            if(child.classList.contains('appear-up')){
                child.classList.remove('appear-up')
            }
        }
    }

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll)
    return ()=>{
        window.removeEventListener('scroll', handleScroll)
    }
  },[])

  return (
    <Wrapper id='smart-tech-wrap'>
        <Title>Technical Details</Title>

        <MainDiv id='smart-tech-main'>

            <TopImg src='/images/smallSmart.png' alt='Smart-breakers'/>

            <TableResponsive className="table-responsive" style={{marginTop:"26vh"}}>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Descriptions</th>
                                    <th>Unit</th>
                                    <th>Impact 40 Smart</th>
                                    <th>Impact 50 Smart</th>
                                    <th>Impact 60 Smart</th>
                                    <th>Impact 70 Smart</th>
                                    <th>Impact 80 Smart</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Operating Weight</td>
                                    <td>Kg</td>
                                    {
                                        small?.opwt.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Height</td>
                                    <td>mm</td>
                                    {
                                        small?.ht.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Required Oil Flow</td>
                                    <td>L/min</td>
                                    {
                                        small?.flow.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Operating Pressure</td>
                                    <td>Bar</td>
                                    {
                                        small?.prs.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Impact Rate</td>
                                    <td>BPM</td>
                                    {
                                        small?.rate.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Chisel Diameter</td>
                                    <td>mm</td>
                                    {
                                        small?.diam.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Carrier Weight</td>
                                    <td>Ton</td>
                                    {
                                        small?.carwt.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </table>
            </TableResponsive>

            <Img src='/images/mediumSmart.png' alt='Smart-breakers'/>


            <TableResponsive className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Descriptions</th>
                                    <th>Unit</th>
                                    <th>Impact 140 Smart</th>
                                    <th>Impact 220+ Smart</th>
                                    <th>Impact 250 Smart</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Operating Weight</td>
                                    <td>Kg</td>
                                    {
                                        medium?.opwt.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Height</td>
                                    <td>mm</td>
                                    {
                                        medium?.ht.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Required Oil Flow</td>
                                    <td>L/min</td>
                                    {
                                        medium?.flow.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Operating Pressure</td>
                                    <td>Bar</td>
                                    {
                                        medium?.prs.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Impact Rate</td>
                                    <td>BPM</td>
                                    {
                                        medium?.rate.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Chisel Diameter</td>
                                    <td>mm</td>
                                    {
                                        medium?.diam.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Carrier Weight</td>
                                    <td>Ton</td>
                                    {
                                        medium?.carwt.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </table>
            </TableResponsive>

            <Img src='/images/heavySmart.png' alt='Smart-breakers'/>

            <TableResponsive className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Descriptions</th>
                                    <th>Unit</th>
                                    <th>Impact 320 Smart</th>
                                    <th>Impact 420 Smart</th>
                                    <th>Impact 520 Smart</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Operating Weight</td>
                                    <td>Kg</td>
                                    {
                                        heavy?.opwt.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Height</td>
                                    <td>mm</td>
                                    {
                                        heavy?.ht.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Required Oil Flow</td>
                                    <td>L/min</td>
                                    {
                                        heavy?.flow.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Operating Pressure</td>
                                    <td>Bar</td>
                                    {
                                        heavy?.prs.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Impact Rate</td>
                                    <td>BPM</td>
                                    {
                                        heavy?.rate.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Chisel Diameter</td>
                                    <td>mm</td>
                                    {
                                        heavy?.diam.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <td>Carrier Weight</td>
                                    <td>Ton</td>
                                    {
                                        heavy?.carwt.map((owt,i)=>(
                                            <td key={i}>{owt}</td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </table>
            </TableResponsive>
                
        </MainDiv>
    </Wrapper>
  )
}

export default SmartTech