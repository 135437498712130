import React from 'react';
import styled from 'styled-components';
import LabelItl from './LabelItl';

const Wrapper = styled.div`
    width: 100%;
    /* height: 200vh; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;
    
    @media (max-width:1050px){
        padding-top: 10vh;
    }
`;

const TitleCard = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 1vh 2vw;
    border-radius:15px;
    color: var(--secondary);
    background-color: var(--primary);
`;

const Title = styled.h1`
  font-size: 3.4em;
  color: var(--secondary);
  font-weight:500;

  @media (max-width:900px){
    padding-left: 5vw;
    font-size: 2.6em;
  }

  @media(max-width:576px){
    font-size: 1.6em;
  }
`;

const ItlSpecs = () => {
  return (
    <Wrapper>
        <TitleCard>
            <Title>
            Silence Type Rock Breaker
            </Title>
        </TitleCard>
        <LabelItl/>

    </Wrapper>
  )
}

export default ItlSpecs