import React from 'react';
import {styled} from 'styled-components';
import Header from '../components/Header';  

const Container = styled.div`
  position: relative;
  min-height: 88vh;
  padding: 0;
`;

const Content = styled.div`
    width: 100%;
    min-height: 88vh;
    margin-top: 14vh;
`;

const ConnectDiv = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;
  position: fixed;
  bottom: 10vh;
  right: 3vw;
  z-index: 15;
`;

const MyWhatsapp = styled.a`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const MyPhoneTag = styled.a`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Img = styled.img`
  width: 5vw;
  height: 100%;
  object-fit: cover;
  filter: drop-shadow(0 0 2px #777);

  @media (max-width: 1000px){
    width: 10vw;
  }

  @media (max-width:576px){
    width: 15vw;
  }

`;

const Layout = ({children}) => {
  const myPhone = process.env.REACT_APP_PHONE
  
  return (
    <Container className='container-fluid'>
      <Header/>
      <Content>
        {children}
      </Content>


      
      <ConnectDiv>
        <MyWhatsapp href={`https://wa.me/${myPhone}`}>
          <Img src='/images/whatsapp-icon.webp' alt='whatsapp-icon' />
        </MyWhatsapp>

        <MyPhoneTag href={`tel:${myPhone}`}>
          <Img src='/images/telecall.webp' alt='telephone-icon' />
        </MyPhoneTag>

      </ConnectDiv>


    </Container>
  )
}

export default Layout