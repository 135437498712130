import React from 'react'
import VideoBox from '../components/VideoBox';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5vw;
  padding: 10vh 3vw;

  @media (max-width: 640px){
    flex-direction: column;
    gap: 4vh;
  }
`;

const Videos = () => {
  return (
    <Wrapper>
      <VideoBox vidSrc="/videos/intro-video.mp4"/>
      <VideoBox vidSrc="/videos/impact-video.mp4"/>

    </Wrapper>
  )
}

export default Videos