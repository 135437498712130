import React from 'react';
import { styled } from 'styled-components';
import { FaXmark } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  color: #ffffff;
  background-color: #2C2E35;
  align-items: center;
  justify-content: center;
  display: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width:800px){
    display: flex;

  }
`;

const Logo = styled.img`
    min-width: 6vw;
    height: 6vh;
    position: absolute;
    top: 5vh;
    left: 5vw;
    background-color: #ffffff;
`;

const MenuList = styled.ul`
    margin: 0;
    padding: 0;
    min-width:50vw;
    height: 70vh;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  
`;

const Item = styled.li`
    padding: 1vh 1vw;
    font-weight:500;
    font-size: 1.4em;
    color: #ffffff;
 
`;

const CloseIconDiv = styled.div`
  position: absolute;
  top: 5vh;
  right: 5vw;
  font-weight:500;
 
`;

const AltNavbar = ({ setShowAlt }) => {
  const navigate = useNavigate();

  const ScrollToMainBanner = () => {
    const target = document.getElementById("mainbanner");
    if (!target) {
      navigate("/");
      setShowAlt(false);
      return
    }
    window.scrollTo({
      top: target?.offsetTop,
      behavior: 'smooth'
    })

    setShowAlt(false);
  }

  const GoToProducts = () => {
    navigate("/product")

    setShowAlt(false);
  }

  const ScrollToAbout = () => {
    const target = document.getElementById("about");
    
    if(!target){
      setTimeout(()=>{
        window.scrollTo({
          top:document.getElementById("about")?.offsetTop,
          behavior:'smooth'
        })
        
      }, 100);
      
      setShowAlt(false);
      navigate("/");

    }else{
      window.scrollTo({
        top:target?.offsetTop,
        behavior:'smooth'
      })
      setShowAlt(false);
    }

  }

  return (
    <Wrapper>
      <Logo src='/redLogo.png' alt='logo' 
      onClick={ScrollToMainBanner}
      style={{cursor:"pointer"}}
      />

      <MenuList>
        <Item onClick={ScrollToMainBanner}>Home</Item>
        <Item onClick={ScrollToAbout}>About</Item>
        <Item onClick={GoToProducts}>Products</Item>
        <Item onClick={()=>{setShowAlt(false);navigate("/gallery")}}>Gallery</Item>
        <Item onClick={()=>{setShowAlt(false); navigate("/contact")}}>Contact</Item>
      </MenuList>

      <CloseIconDiv onClick={() => setShowAlt(false)}>
        {/* <Close /> */}
        <FaXmark />
      </CloseIconDiv>
    </Wrapper>
  )
}

export default AltNavbar