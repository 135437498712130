import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    /* border: 2px solid red; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Img = styled.img`
  width: 100%;
  height: 66vh;
  /* height: 100%; */
  object-fit: fill;

  @media (max-width:576px){
    height: 40vh;
  }
`;

const ImgSlider = () => {
  const navigate = useNavigate();
  const imgArray = ["show1.jpg","show2.jpg","show3.jpg","show4.jpg"]

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Wrapper >
    <Carousel activeIndex={index} onSelect={handleSelect} interval={null} >
      <Carousel.Item >
        <Img src={`/images/gallery/${imgArray[0]}`} alt='' onClick={(e) =>{ navigate("/images")}}/>

      </Carousel.Item>
      <Carousel.Item>
        <Img src={`/images/gallery/${imgArray[1]}`} alt='' onClick={(e) =>{ navigate("/images")}}/>
  
      </Carousel.Item>
      <Carousel.Item>
        <Img src={`/images/gallery/${imgArray[2]}`} alt='' onClick={(e) =>{ navigate("/images")}}/>

      </Carousel.Item>
      <Carousel.Item>
        <Img src={`/images/gallery/${imgArray[3]}`} alt='' onClick={(e) =>{ navigate("/images")}}/>

      </Carousel.Item>
    </Carousel>

    </Wrapper>
  )
}

export default ImgSlider;