import React, { useState } from 'react';
import {styled} from 'styled-components';
import { FaBars } from "react-icons/fa6";
import AltNavbar from './AltNavbar';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
  height: 14vh;
  /* border: 2px solid black; */
  display: flex;
  align-items: center;
  position: relative;
`;

const Logo = styled.img`
    min-width: 8vw;
    height: 8vh;
    margin-left: 5vw;
`;

const MenuList = styled.ul`
    min-width: 30vw;
    height: 10vh;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 5vw;
    margin: 0;
    padding: 0;

    @media(max-width:800px){
      display: none;
    }
`;

const Item = styled.li`
    padding: 1vh 1vw;
    font-weight:500;
    font-size: 1.1em;

    &:hover{
        border-radius: 5px;
        color: #ffffff;
        background-color: #FFC734;
        cursor: pointer;
    }

    
`;

const MenuIconDiv =styled.div`
  position: absolute;
  right: 5vw;
  font-weight:500;
  display: none;
  @media(max-width:800px){
      display: block;
    }
 
`;

const Navbar = () => {
  const navigate = useNavigate();
  const [showAlt,setShowAlt] = useState(false)

  const ScrollToMainBanner = ()=>{
    const target = document.getElementById("mainbanner");
    if(!target){
      navigate("/");
      return
    }
    window.scrollTo({
      top:target?.offsetTop,
      behavior:'smooth'
    })
  }

  const ScrollToAbout = ()=>{
    const target = document.getElementById("about");
    if(!target){
      setTimeout(()=>{
        window.scrollTo({
          top:document.getElementById("about")?.offsetTop,
          behavior:'smooth'
        })
        
      }, 100)

      navigate("/");

    }else{
      window.scrollTo({
        top:target?.offsetTop,
        behavior:'smooth'
      })
    }

  }

  

  return (
    <Wrapper>
        <Logo src='/redLogo.png' alt='logo'
        onClick={ScrollToMainBanner}
        style={{cursor:"pointer"}}
        /> 
        
        <MenuList>
            <Item onClick={ScrollToMainBanner}>Home</Item>
            <Item onClick={ScrollToAbout}>About</Item>
            <Item onClick={()=>navigate("/product")}>Products</Item>
            <Item onClick={()=>navigate("/gallery")}>Gallery</Item>
            <Item onClick={()=> navigate("/contact")}>Contact</Item>
        </MenuList>

        <MenuIconDiv onClick={()=> setShowAlt(true)}>
          {/* <Menu/> */}
          <FaBars />
        </MenuIconDiv>

        {showAlt && <AltNavbar setShowAlt={setShowAlt}/>}
    </Wrapper>
  )
}

export default Navbar