import React, { useState } from 'react';
import {styled,keyframes} from 'styled-components';
import ImgSlider from './ImgSlider';
import { useNavigate } from 'react-router-dom';

const fadeInAnimation = keyframes`
  from{
    opacity: 0;
    transform: translateY(10px);
  }

  to{
    opacity: 1;
    transform: translateY(0px);
  }
`;

const growAnime = keyframes`
  0%{
    transform: scale(0.8);
  }
  100%{
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  /* min-height: 120vh; */
  min-height: 100vh;
  background-color: #2C2E35;
  border-top: 2vh solid var(--primary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  @media(max-width:1000px){
    align-items: center;
    padding: 5vh 0;
    gap: 4vh;
  }
`;

const LeftTop = styled.div`
  width: 50vw;
  height: 30vh;
  /* border: 2px solid orange; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5vh 0 0 5vw;

  @media (max-width:1000px){
    width: 90%;
  }
`;

const LeftBottom = styled.div`
  width: 60vw;
  height: 52vh;
  background-color: #ffffff;
  border-radius: 0 40% 40% 0 / 100% 100% 100% 100%;
  display: flex;
  justify-content: space-between;
  /* gap: 7vw; */
  padding: 0 0 0 5vw;

  @media (max-width:1000px){
    width: 80%;
    min-height: 45vh;
    height: auto;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    /* padding: 1vh 1vw; */
    padding: 2vh 0;

  }
`;

const Title = styled.h1`
  font-size: 3.4em;
  color: #ffffff;
  font-weight:500;

  @media (max-width:1000px){
    font-size: 3em;
  }

  @media(max-width:500px){
    font-size: 2.5em;
  }
  
`;

const Subtitle = styled.h5`
  color: #ffffff;
  font-weight:300;
  text-align: left;
`;

const DetailDiv = styled.div`
  width: 50%;
  height: 50%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 6vh;


  @media (max-width:1000px){
    width: 90%;
    margin-top: 0;

  }
`;

const ParaWho = styled.p`
  font-size: 16px;
  text-align: left;
  font-weight:400;
  width: 26vw;
  word-wrap: break-word;
  line-height: 1.2em;
  animation: ${fadeInAnimation} 1.5s ease-in-out;

  @media (max-width:1000px){

  text-align: left;
  font-weight:400;
  width: 100%;
  line-height: 1.2em;
  }
`;

const ParaGal = styled.p`
  font-size: 16px;
  text-align: left;
  font-weight:400;
  width: 26vw;
  word-wrap: break-word;
  line-height: 1.2em;
  animation: ${fadeInAnimation} 1.5s ease-in-out;

  @media (max-width:1000px){

  text-align: left;
  font-weight:400;
  width: 100%;
  line-height: 1.2em;
  }
`;


const SwitchDiv = styled.div`
  width: 15vw;
  height: 30vh;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1vh;
  margin-right: 6vw;
  margin-top: 10vh;

  @media (max-width:1000px){
    width: 80%;
    height: 10vh;
    align-items: center;
    flex-direction: row;
    margin-top: 1vh;
    margin-right: 0;
  }
`;


const WhoSwt = styled.h5`
  cursor: pointer;
`;

const GalSwt = styled.h5`
  margin-left: 4vw;
  cursor: pointer;
`;

const GalleryDiv = styled.div`
  width: 43vw;
  height: 70vh;
  border: 2vh solid #FFC734;
  border-radius: 15px;
  background-color: #FFC734;
  position: absolute;
  top: 18vh;
  right: 7vw;
  box-shadow: -5px 5px 5px 5px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width:1200px){
    top: 34vh;
    right: 5vw;
    height: 45vh;
  }

  @media (max-width:1000px){
    position: static;
    width: 60%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width:600px){
    width: 80%;
  }
`;

const SeeMore = styled.h6`
  cursor: pointer;
  margin-top: 2vh;
`;

const VideoElem = styled.video`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  animation: ${growAnime} 1s ease-in-out;
  object-fit: fill;
`;

const AboutUs = () => {
  const [galHovered, setGalHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <Wrapper id='about'>
      <LeftTop>
        <Title>About Us</Title>
        <Subtitle>Building trust with our efforts to continually grow our technology!</Subtitle>
      </LeftTop>  

      <GalleryDiv>
        {
          galHovered 
          ?
          <ImgSlider/>
          :
          <VideoElem controls 
            autoPlay 
            muted>
            <source src='/videos/intro-video.mp4' type='video/mp4'/>
          </VideoElem>
        }
      </GalleryDiv>

      <LeftBottom>

        <DetailDiv id='detail-about' className='detail-about-class'>
        {
          galHovered ?
          <>
            <h5>Gallery</h5>
            <ParaGal>
              Some glimpses of our products in work environment
            <SeeMore onClick={()=>navigate("/gallery")}>See more...</SeeMore>
            </ParaGal>
          </>
          :
          <>
            <h5>Who are we</h5>
            <ParaWho>Backed by a team of skilled professionals and cutting-edge 
              technology, we strive to meet the evolving needs of the 
              construction industry. Discover precision engineering, 
              reliability, and innovation with Impact Engineering as we 
              continue to redefine standards in the rock breaking equipment 
              sector</ParaWho>
          </>
        }
          </DetailDiv>
       
        
        <SwitchDiv>
          <WhoSwt className='switch'
          onClick={()=>setGalHovered(false)}
          style={galHovered ? {opacity:0.5} : {opacity:1,borderBottom:"2px solid #2C2E35"}}>
            Who are we
          </WhoSwt>

          <GalSwt className='switch'
          onClick={()=>setGalHovered(true)}
          style={galHovered ? {opacity:1,borderBottom:"2px solid #2C2E35"} : {opacity:0.5}}>
            Gallery
          </GalSwt>
        </SwitchDiv>
      </LeftBottom>

      
    </Wrapper>
  )
}

export default AboutUs